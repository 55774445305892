import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";

import { IMainContext } from "../interface/interface";
import { MainContext } from "../common/context";

export default function Layout(): JSX.Element {
  const context = useContext<IMainContext>(MainContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    const storage = localStorage.getItem("isUser");
    if (storage === "user") {
      context.handleStateChange("isUser", true);
    }
  }, []);

  const handleLogout = () => {
    context.handleStateChange("isUser", false);
    context.handleStateChange("userInfo", {});
    localStorage.removeItem("isUser");
    navigate("/login");
  };

  return (
    <>
      {process.env.REACT_APP_COMPANYNAME === "(주식회사) 준누리" && (
        <div className="">
          <div className="bg-black pb-3 sm:px-0 px-2">
            <div className="container mx-auto pt-6">
              <div className="flex justify-between items-center">
                <Link to="/">
                  <p className="text-2xl font-medium text-white">{process.env.REACT_APP_COMPANYNAME}__</p>
                </Link>
                <div className="flex justify-row items-center">
                  {!context.isUser && (
                    <Link to="/login">
                      <p className="text-sm font-light text-gray-500 hover:text-gray-100 hover:font-medium hover:underline mr-5">
                        로그인
                      </p>
                    </Link>
                  )}
                  {context.isUser && (
                    <p
                      onClick={handleLogout}
                      className="text-sm font-light text-gray-500 cursor-pointer hover:text-gray-100 hover:font-medium hover:underline mr-5"
                    >
                      로그아웃
                    </p>
                  )}
                  {context.isUser && (
                    <Link to="/user/mypage">
                      <p className="text-sm font-light text-gray-500 hover:text-gray-100 hover:font-medium hover:underline mr-5">
                        마이페이지
                      </p>
                    </Link>
                  )}
                  <Link to="/notice">
                    <p className="text-sm font-light text-gray-500 hover:text-gray-100 hover:font-medium hover:underline">
                      고객센터
                    </p>
                  </Link>
                </div>
              </div>

              <div className="mt-5 flex justify-between items-center">
                <div className="flex justify-row">
                  <Link to="/">
                    <div className="border-b-4 border-white pb-2 mr-7">
                      <p className="text-lg font-bold text-white ">추천</p>
                    </div>
                  </Link>
                  {/* <Link to="/"> */}
                  <p
                    onClick={() => alert("카테고리 준비중입니다.")}
                    className="text-lg font-bold text-gray-500 cursor-pointer"
                  >
                    카테고리
                  </p>
                  {/* </Link> */}
                </div>
                <div className="pb-1 flex justify-between pr-2">
                  <input
                    autoComplete="off"
                    type="text"
                    id="default-input"
                    placeholder="검색어를 입력해 주세요."
                    className="bg-[#1c1c1c] w-[300px] text-gray-200 text-sm placeholder:text-sm placeholder:text-gray-600  rounded outline-none py-2.5 pl-3"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="white"
                    className="w-5 ml-[-35px]"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <Outlet />

          <div className="mt-36 mb-10">
            <div className="py-3 border border-gray-200 sm:px-0 px-3">
              <div className="container mx-auto">
                <div className="grid md:grid-cols-2 sm:grid-cols-1">
                  <div>
                    <div className="grid md:grid-cols-4 sm:grid-cols-1 ">
                      <Link to="/">
                        <p className="text-sm text-gray-600">회사소개</p>
                      </Link>
                      <Link to="/">
                        <p className="text-sm text-gray-600">이용약관</p>
                      </Link>
                      <Link to="/">
                        <p className="text-sm text-gray-600">개인정보처리방침</p>
                      </Link>
                    </div>
                  </div>
                  <div>{/* 빈공간 */}</div>
                </div>
              </div>
            </div>

            <div className="container mx-auto sm:px-0 px-3">
              <p className="font-medium text-gray-800 mt-7">{process.env.REACT_APP_COMPANYNAME}</p>

              <p className="text-xs font-light text-gray-500 mt-3">{process.env.REACT_APP_COMPANYINFO}</p>
              <p className="text-xs font-light text-gray-500">{process.env.REACT_APP_COMPANYLICENSE}</p>

              <p className="text-xs font-light text-gray-500 mt-5">{process.env.REACT_APP_COMPANYADDRESS}</p>
              <p className="text-xs font-light text-gray-500">{process.env.REACT_APP_COMPANYADDRESS2}</p>
              <p className="text-xs font-light text-gray-500">호스팅 서비스: 마이크로소프트</p>

              <p className="text-xs font-light text-gray-400 mt-7">
                {process.env.REACT_APP_COMPANYNAME}은(는) 통신판매 중개자로서 통신판매의 당사자가 아니므로 개별판매자가
                등록한 상품정보에 대해서 책임지지 않습니다. 단, 거래과정에서 검수하고 보증하는 내용에 대한 책임은 당사에
                있습니다. 당사는 고객님의 안전거래를 위해 관련 법률에 의거하여 NHN KCP의 에스크로서비스를 적용하고
                있습니다.
              </p>
            </div>
          </div>
        </div>
      )}

      {process.env.REACT_APP_COMPANYNAME === "주식회사 키온플러스" && (
        <div className="bg-gray-50 min-h-screen">
          {/* 헤더 */}
          <header className="bg-white shadow-md py-4">
            <div className="container mx-auto flex justify-between items-center">
              <Link to="/">
                <h1 className="text-lg font-bold text-gray-800">{process.env.REACT_APP_COMPANYNAME}</h1>
              </Link>
              <div className="flex space-x-4">
                {!context.isUser && (
                  <Link to="/login" className="text-sm text-gray-500 hover:text-gray-700">
                    로그인
                  </Link>
                )}
                {context.isUser && (
                  <button onClick={handleLogout} className="text-sm text-gray-500 hover:text-gray-700">
                    로그아웃
                  </button>
                )}
                {context.isUser && (
                  <Link to="/user/mypage" className="text-sm text-gray-500 hover:text-gray-700">
                    마이페이지
                  </Link>
                )}
                <Link to="/notice" className="text-sm text-gray-500 hover:text-gray-700">
                  고객센터
                </Link>
              </div>
            </div>
          </header>

          {/* 검색 및 네비게이션 */}
          <nav className="bg-gray-100 py-3">
            <div className="container mx-auto flex justify-between items-center">
              <div className="flex space-x-6">
                <Link to="/" className="text-sm font-medium text-gray-800 border-b-2 border-blue-500">
                  추천
                </Link>
                <button onClick={() => alert("카테고리 준비중입니다.")} className="text-sm text-gray-500">
                  카테고리
                </button>
              </div>
              <div className="relative w-80">
                <input
                  type="text"
                  placeholder="검색어를 입력해 주세요."
                  className="w-full py-2 px-4 text-sm text-gray-800 border rounded shadow-sm focus:outline-none"
                />
                <svg
                  className="absolute top-1/2 right-3 transform -translate-y-1/2 w-5 h-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
                </svg>
              </div>
            </div>
          </nav>

          <Outlet />

          {/* 푸터 */}
          <footer className="bg-white py-8 border-t">
            <div className="container mx-auto text-center">
              <div className="flex justify-center space-x-6">
                <Link to="/" className="text-sm text-gray-600">
                  회사소개
                </Link>
                <Link to="/" className="text-sm text-gray-600">
                  이용약관
                </Link>
                <Link to="/" className="text-sm text-gray-600">
                  개인정보처리방침
                </Link>
              </div>
              <p className="text-sm text-gray-600 mt-4">{process.env.REACT_APP_COMPANYNAME}</p>
              <p className="text-xs text-gray-500">{process.env.REACT_APP_COMPANYINFO}</p>
              <p className="text-xs text-gray-500">{process.env.REACT_APP_COMPANYADDRESS}</p>
              <p className="text-xs text-gray-500 mt-2">
                {process.env.REACT_APP_COMPANYNAME}은 통신판매 중개자로서, 개별 판매자의 상품정보에 대한 책임을 지지
                않습니다.
              </p>
            </div>
          </footer>
        </div>
      )}
      {process.env.REACT_APP_COMPANYNAME === "주식회사 루이빌" && (
        <div className="bg-gradient-to-r from-indigo-500 to-purple-600 min-h-screen text-white">
          {/* 헤더 */}
          <header className="py-6">
            <div className="container mx-auto flex justify-between items-center">
              <Link to="/">
                <h1 className="text-3xl font-extrabold">주라기몰::juragimall</h1>
              </Link>
              <div className="flex space-x-6">
                {!context.isUser && (
                  <Link to="/login" className="hover:underline">
                    로그인
                  </Link>
                )}
                {context.isUser && (
                  <button onClick={handleLogout} className="hover:underline">
                    로그아웃
                  </button>
                )}
                {context.isUser && (
                  <Link to="/user/mypage" className="hover:underline">
                    마이페이지
                  </Link>
                )}
                <Link to="/notice" className="hover:underline">
                  고객센터
                </Link>
              </div>
            </div>
          </header>

          {/* 검색 */}
          <div className="py-6">
            <div className="container mx-auto text-center">
              <input
                type="text"
                placeholder="검색어를 입력하세요"
                className="w-full max-w-lg py-2 px-4 rounded-full bg-white text-gray-800 shadow-md focus:outline-none"
              />
            </div>
          </div>

          <Outlet />

          {/* 푸터 */}
          <footer className="bg-purple-700 py-8 mt-[300px]">
            <div className="container mx-auto text-center">
              <p className="font-medium text-gray-800 mt-7">{process.env.REACT_APP_COMPANYNAME}</p>

              <p className="text-xs font-light text-gray-500 mt-3">{process.env.REACT_APP_COMPANYINFO}</p>
              <p className="text-xs font-light text-gray-500">{process.env.REACT_APP_COMPANYLICENSE}</p>

              <p className="text-xs font-light text-gray-500 mt-5">{process.env.REACT_APP_COMPANYADDRESS}</p>
              <p className="text-xs font-light text-gray-500">{process.env.REACT_APP_COMPANYADDRESS2}</p>
              <p className="text-xs font-light text-gray-500">호스팅 서비스: 마이크로소프트</p>

              <p className="text-xs font-light text-gray-400 mt-7">
                {process.env.REACT_APP_COMPANYNAME}은(는) 통신판매 중개자로서 통신판매의 당사자가 아니므로 개별판매자가
                등록한 상품정보에 대해서 책임지지 않습니다. 단, 거래과정에서 검수하고 보증하는 내용에 대한 책임은 당사에
                있습니다. 당사는 고객님의 안전거래를 위해 관련 법률에 의거하여 NHN KCP의 에스크로서비스를 적용하고
                있습니다.
              </p>
            </div>
          </footer>
        </div>
      )}
      {process.env.REACT_APP_COMPANYNAME === "주식회사 하비정보스토어" && (
        <div className="">
          <div className="bg-[#111111] pb-3 sm:px-0 px-4">
            <div className="container mx-auto pt-6">
              <div className="flex justify-between items-center">
                <Link to="/">
                  <p className="text-3xl font-semibold text-[#f0e6d2] tracking-widest">
                    {process.env.REACT_APP_COMPANYNAME}
                  </p>
                </Link>
                <div className="flex justify-row items-center">
                  {!context.isUser && (
                    <Link to="/login">
                      <p className="text-sm font-medium text-gray-300 hover:text-[#f0e6d2] hover:underline mr-5">
                        로그인
                      </p>
                    </Link>
                  )}
                  {context.isUser && (
                    <p
                      onClick={handleLogout}
                      className="text-sm font-medium text-gray-300 cursor-pointer hover:text-[#f0e6d2] hover:underline mr-5"
                    >
                      로그아웃
                    </p>
                  )}
                  {context.isUser && (
                    <Link to="/user/mypage">
                      <p className="text-sm font-medium text-gray-300 hover:text-[#f0e6d2] hover:underline mr-5">
                        마이페이지
                      </p>
                    </Link>
                  )}
                  <Link to="/notice">
                    <p className="text-sm font-medium text-gray-300 hover:text-[#f0e6d2] hover:underline">고객센터</p>
                  </Link>
                </div>
              </div>

              <div className="mt-5 flex justify-between items-center">
                <div className="flex justify-row">
                  <Link to="/">
                    <div className="border-b-4 border-[#f0e6d2] pb-2 mr-7">
                      <p className="text-lg font-semibold text-[#f0e6d2]">추천</p>
                    </div>
                  </Link>
                  <p
                    onClick={() => alert("카테고리 준비중입니다.")}
                    className="text-lg font-semibold text-gray-400 cursor-pointer hover:text-[#f0e6d2]"
                  >
                    인기순
                  </p>
                </div>
                <div className="pb-1 flex justify-between pr-2">
                  <input
                    autoComplete="off"
                    type="text"
                    id="default-input"
                    placeholder="검색어를 입력해 주세요."
                    className="bg-[#1e1e1e] w-[300px] text-[#f0e6d2] text-sm placeholder:text-sm placeholder:text-gray-500 rounded outline-none py-2.5 pl-3 focus:ring-[#f0e6d2] focus:border-[#f0e6d2]"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#f0e6d2"
                    className="w-5 ml-[-35px]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <Outlet />

          <div className="mt-36 mb-10">
            <div className="py-3 border-t border-[#444444] sm:px-0 px-4">
              <div className="container mx-auto">
                <div className="grid md:grid-cols-2 sm:grid-cols-1">
                  <div>
                    <div className="grid md:grid-cols-4 sm:grid-cols-1">
                      <Link to="/">
                        <p className="text-sm text-gray-400 hover:text-[#f0e6d2]">회사소개</p>
                      </Link>
                      <Link to="/">
                        <p className="text-sm text-gray-400 hover:text-[#f0e6d2]">이용약관</p>
                      </Link>
                      <Link to="/">
                        <p className="text-sm text-gray-400 hover:text-[#f0e6d2]">개인정보처리방침</p>
                      </Link>
                    </div>
                  </div>
                  <div>{/* 빈공간 */}</div>
                </div>
              </div>
            </div>

            <div className="container mx-auto sm:px-0 px-4">
              <p className="font-bold text-[#f0e6d2] mt-7">{process.env.REACT_APP_COMPANYNAME}</p>

              <p className="text-xs font-light text-gray-400 mt-3">{process.env.REACT_APP_COMPANYINFO}</p>
              <p className="text-xs font-light text-gray-400">{process.env.REACT_APP_COMPANYLICENSE}</p>

              <p className="text-xs font-light text-gray-400 mt-5">{process.env.REACT_APP_COMPANYADDRESS}</p>
              <p className="text-xs font-light text-gray-400">{process.env.REACT_APP_COMPANYADDRESS2}</p>
              <p className="text-xs font-light text-gray-400">호스팅 서비스: 마이크로소프트</p>

              <p className="text-xs font-light text-gray-500 mt-7">
                {process.env.REACT_APP_COMPANYNAME}은(는) 통신판매 중개자로서 통신판매의 당사자가 아니므로 개별판매자가
                등록한 상품정보에 대해서 책임지지 않습니다. 단, 거래과정에서 검수하고 보증하는 내용에 대한 책임은 당사에
                있습니다. 당사는 고객님의 안전거래를 위해 관련 법률에 의거하여 NHN KCP의 에스크로서비스를 적용하고
                있습니다.
              </p>
            </div>
          </div>
        </div>
      )}
      {process.env.REACT_APP_COMPANYNAME === "유한회사 프레테일" && (
        <div className="bg-gray-900 text-gray-200 min-h-screen">
          {/* 헤더 */}
          <header className="bg-gray-800 py-5 shadow-md">
            <div className="container mx-auto flex justify-between items-center">
              <Link to="/">
                <h1 className="text-xl font-bold tracking-wide text-gray-200">{process.env.REACT_APP_COMPANYNAME}</h1>
              </Link>
              <div className="flex items-center space-x-6">
                {!context.isUser && (
                  <Link to="/login" className="text-sm hover:text-gray-100 transition">
                    로그인
                  </Link>
                )}
                {context.isUser && (
                  <button onClick={handleLogout} className="text-sm hover:text-gray-100 transition">
                    로그아웃
                  </button>
                )}
                {context.isUser && (
                  <Link to="/user/mypage" className="text-sm hover:text-gray-100 transition">
                    마이페이지
                  </Link>
                )}
                <Link to="/notice" className="text-sm hover:text-gray-100 transition">
                  고객센터
                </Link>
              </div>
            </div>
          </header>

          {/* 검색 및 네비게이션 */}
          <nav className="bg-gray-700 py-4">
            <div className="container mx-auto flex justify-between items-center">
              <div className="flex space-x-6">
                <Link to="/" className="text-sm font-medium text-gray-100 border-b-2 border-cyan-400"></Link>
                <button
                  onClick={() => alert("카테고리 준비중입니다.")}
                  className="text-sm text-gray-300 hover:text-gray-100 transition"
                >
                  공지사항
                </button>
              </div>
              <div className="relative w-80">
                <input
                  type="text"
                  placeholder="검색어를 입력하세요."
                  className="w-full py-2 px-4 bg-gray-800 text-gray-200 placeholder-gray-500 rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-cyan-400"
                />
                <svg
                  className="absolute top-1/2 right-3 transform -translate-y-1/2 w-5 h-5 text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
                </svg>
              </div>
            </div>
          </nav>

          <main>
            <Outlet />
          </main>

          {/* 푸터 */}
          <footer className="bg-gray-800 py-10 mt-20">
            <div className="container mx-auto">
              <div className="grid grid-cols-2 gap-6 md:flex md:justify-between">
                <Link to="/" className="text-sm text-gray-400 hover:text-gray-200 transition">
                  회사소개
                </Link>
                <Link to="/" className="text-sm text-gray-400 hover:text-gray-200 transition">
                  이용약관
                </Link>
                <Link to="/" className="text-sm text-gray-400 hover:text-gray-200 transition">
                  개인정보처리방침
                </Link>
              </div>
              <p className="mt-6 text-sm text-gray-400 text-center">{process.env.REACT_APP_COMPANYNAME}</p>
              <p className="text-xs text-gray-500 text-center mt-2">{process.env.REACT_APP_COMPANYINFO}</p>
              <p className="text-xs text-gray-500 text-center mt-2">{process.env.REACT_APP_COMPANYADDRESS}</p>
              <p className="text-xs text-gray-600 text-center mt-6">
                &copy; 2024 {process.env.REACT_APP_COMPANYNAME}. All rights reserved.
              </p>
            </div>
          </footer>
        </div>
      )}
    </>
  );
}
