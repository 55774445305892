import React, { useState } from "react";
import { currency } from "../method";
import { Link } from "react-router-dom";
import ProductCard from "../components/ProductCard";
import image1 from "../assets/image1.png";
import PRODUCT_DATA from "../data/data";

export default function Home(): JSX.Element {
  const [data, setData] = useState<any>(process.env.REACT_APP_COMPANYNAME === "(주식회사) 준누리" ? PRODUCT_DATA : []);
  const [isOpen, setIsOpen] = useState(true);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <>
      {process.env.REACT_APP_COMPANYNAME === "(주식회사) 준누리" && (
        <div className="container mx-auto px-4">
          <div className="pt-12 pb-6">
            <h1 className="text-2xl font-semibold text-gray-800">신규 상품</h1>
          </div>
          <div className="grid lg:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-4">
            {data.map((el: any, key: string) => (
              <ProductCard productItem={el} key={key} />
            ))}
          </div>

          <div className="pt-12 pb-6">
            <h1 className="text-2xl font-semibold text-gray-800">추천 상품</h1>
          </div>
          <div className="grid lg:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-4">
            {data.map((el: any, key: string) => (
              <ProductCard productItem={el} key={key} />
            ))}
          </div>

          {/* Modal */}
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-70 z-50">
            <div className="bg-white rounded-xl shadow-md p-8 w-96 text-center">
              <h2 className="text-lg font-bold text-gray-800 mb-4">서비스 종료 안내</h2>
              <p className="text-sm text-gray-600">
                원청사의 일방적인 공급 계약 해지로 서비스가 종료됩니다. 이용해주셔서 감사합니다.
              </p>
            </div>
          </div>
        </div>
      )}
      {process.env.REACT_APP_COMPANYNAME === "주식회사 키온플러스" && (
        <div className="container mx-auto px-4">
          <div className="pt-12 pb-6">
            <h1 className="text-2xl font-semibold text-gray-800">신규 상품</h1>
          </div>
          <div className="grid lg:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-4">
            {data.map((el: any, key: string) => (
              <ProductCard productItem={el} key={key} />
            ))}
          </div>

          <div className="pt-12 pb-6">
            <h1 className="text-2xl font-semibold text-gray-800">추천 상품</h1>
          </div>
          <div className="grid lg:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-4">
            {data.map((el: any, key: string) => (
              <ProductCard productItem={el} key={key} />
            ))}
          </div>

          {/* Modal */}
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-70 z-50">
            <div className="bg-white rounded-xl shadow-md p-8 w-96 text-center">
              <h2 className="text-lg font-bold text-gray-800 mb-4">서비스 종료 안내</h2>
              <p className="text-sm text-gray-600">
                원청사의 일방적인 공급 계약 해지로 서비스가 종료됩니다. 이용해주셔서 감사합니다.
              </p>
            </div>
          </div>
        </div>
      )}
      {process.env.REACT_APP_COMPANYNAME === "주식회사 루이빌" && (
        <div className="container mx-auto px-4">
          {/* 헤더 섹션 */}
          <div className="pt-10 pb-5 text-center">
            <h1 className="text-4xl font-bold text-blue-600 uppercase">🔥 신규 상품 🔥</h1>
          </div>
          <div className="grid xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4">
            {data.map((el: any, key: string) => (
              <ProductCard productItem={el} key={key} />
            ))}
          </div>

          <div className="pt-10 pb-5 text-center">
            <h1 className="text-4xl font-bold text-green-600 uppercase">🌟 추천 상품 🌟</h1>
          </div>
          <div className="grid xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4">
            {data.map((el: any, key: string) => (
              <ProductCard productItem={el} key={key} />
            ))}
          </div>

          {/* Modal */}
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40 z-50">
            <div className="bg-gradient-to-r from-blue-500 to-green-500 text-white rounded-xl shadow-lg p-8 w-96">
              <h2 className="text-2xl font-extrabold mb-4 text-center">🚨 서비스 종료 안내 🚨</h2>
              <p className="text-md">
                쇼핑몰 운영이 종료됩니다. 지금까지 저희를 사랑해 주셔서 감사합니다. 더 나은 모습으로 찾아뵐 날을
                기대하며, 불편을 드려 죄송합니다.
              </p>
            </div>
          </div>
        </div>
      )}
      {process.env.REACT_APP_COMPANYNAME === "주식회사 하비정보스토어" && (
        <div className="container mx-auto px-4">
          <div className="py-10">{/* <h1 className="text-4xl font-extrabold text-blue-600">🛍️ 신규 상품</h1> */}</div>
          <div className="grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-3">
            {data.map((el: any, key: string) => (
              <ProductCard productItem={el} key={key} />
            ))}
          </div>

          <div className="py-10">{/* <h1 className="text-4xl font-extrabold text-blue-600">🔥 추천 상품</h1> */}</div>
          <div className="grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-3">
            {data.map((el: any, key: string) => (
              <ProductCard productItem={el} key={key} />
            ))}
          </div>

          {/* Modal */}
          <div className="fixed inset-0 flex items-center justify-center bg-blue-900 bg-opacity-80 z-50">
            <div className="bg-white rounded-lg shadow-lg p-8 text-center">
              <h2 className="text-xl font-bold text-red-600 mb-4">⚠️ 서비스 종료 안내</h2>
              <p className="text-gray-700">그동안 이용해 주신 모든 분들께 감사드리며, 죄송한 마음 전합니다.</p>
            </div>
          </div>
        </div>
      )}
      {process.env.REACT_APP_COMPANYNAME === "유한회사 프레테일" && (
        <div className="container mx-auto px-6">
          <div className="pt-12 pb-6">{/* <h1 className="text-2xl font-bold text-green-700">🌱 신규 상품</h1> */}</div>
          <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4">
            {data.map((el: any, key: string) => (
              <ProductCard productItem={el} key={key} />
            ))}
          </div>

          <div className="pt-12 pb-6">{/* <h1 className="text-2xl font-bold text-green-700">💚 추천 상품</h1> */}</div>
          <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4">
            {data.map((el: any, key: string) => (
              <ProductCard productItem={el} key={key} />
            ))}
          </div>

          {/* Modal */}
          <div className="fixed inset-0 flex items-center justify-center bg-gray-200 bg-opacity-40 z-50">
            <div className="bg-white rounded-xl shadow-xl p-6 w-80">
              <h2 className="text-lg font-semibold text-green-700 mb-3">안내</h2>
              <p className="text-gray-600 text-sm">
                쇼핑몰을 운영 종료하게 되어 죄송합니다. 그동안의 관심과 사랑에 감사드립니다.
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
