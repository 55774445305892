import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import ScrollTop from "./components/ScrollTop";
import "./styles/index.css";

// .env 파일에서 설정한 TITLE 가져오기
const appTitle = process.env.REACT_APP_COMPANYNAME || "환영합니다.";
// 페이지 제목 업데이트
document.title = appTitle;

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <BrowserRouter>
    <ScrollTop />
    <App />
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
